<template>
  <router-view />
</template>

<script>
import energyCustomersModule from '../../store/energy-customers'
import energySitesModule from '../../store/energy-sites'

export default {
  beforeCreate () {
    if (!this.$store.hasModule('energy-customers')) {
      this.$store.registerModule('energy-customers', energyCustomersModule)
    }
    if (!this.$store.hasModule('energy-sites')) {
      this.$store.registerModule('energy-sites', energySitesModule)
    }
  },
  destroyed () {
    if (!this.$store.hasModule('energy-customers')) {
      this.$store.registerModule('energy-customers')
    }
    if (this.$store.hasModule('energy-sites')) {
      this.$store.unregisterModule('energy-sites')
    }
  }
}
</script>
