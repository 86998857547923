<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Site</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-card-text class="d-flex flex-column flex-lg-row justify-space-between">
                  <div class="flex-fill" v-if="!selectedCustomer || selectedCustomer.id !== this.customer_id">
                    <p class="h3 font-weight-bold text-muted">Assign a customer</p>
                    <p class="mb-0 text-muted">A site must belong to a customer.</p>
                  </div>
                  <div class="flex-fill" v-if="(selectedCustomer && selectedCustomer.id) === this.customer_id">
                    <p class="h3 font-weight-bold text-primary">{{ selectedCustomer.company_name }}</p>
                    <p class="mb-0 text-muted">{{ selectedCustomer.address && selectedCustomer.address.address_snippet }}</p>
                  </div>
                  <div class="align-self-lg-center d-flex flex-column mt-2 mt-lg-0">
                    <b-button block variant="secondary" v-b-modal.assign-customer>Assign Customer</b-button>
                  </div>
                </b-card-text>
              </b-card>
              <b-modal id="assign-customer" size="lg" cancel-title="Close" ok-title="Assign"
                title="Assign Customer" ok-variant="secondary" cancel-variant="dark" :ok-disabled="!selectedCustomer"
                @ok="customer_id = selectedCustomer.id" body-class="p-0">
                <div class="p-3 pb-0">
                  <b-form-group class="mb-0" label="Customer Search" label-for="customer-search" label-sr-only>
                    <b-form-input debounce="500" @update="fetchManyCustomers({ query: customerSearchQuery })" id="customer-search" v-model="customerSearchQuery" v-b-popover.hover.top="'Search for customers that have already been added to the system.'" />
                  </b-form-group>
                </div>
                <div class="text-center mb-2" v-if="isSearching">
                  <b-spinner variant="secondary" />
                </div>
                <b-list-group class="border-top" flush v-if="!isSearching && customerSearchResults && customerSearchResults.length">
                  <b-list-group-item button @click.prevent="selectedCustomer = customer" :class="{'bg-selected': customer.id === (selectedCustomer && selectedCustomer.id)}" :key="customer.id" v-for="customer in customerSearchResults">
                    <div class="d-flex flex-column flex-fill">
                      <p class="h5 flex-fill mb-0 text-primary">{{ customer.company_name }}</p>
                      <p class="text-muted mb-0"><small>{{ customer.company_number }} - {{ customer.address && customer.address.address_snippet }}</small></p>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-modal>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="8">
                  <h2 class="font-weight-bold h4">Site Address</h2>
                </b-col>
                <b-col class="mb-2" cols="12" md="4">
                  <b-button block @click.prevent="address = selectedCustomer.address" :disabled="!selectedCustomer" variant="primary">Copy Customer Address</b-button>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors, 'address.address_line_1')" :state="validationState(errors, 'address.address_line_1')">
                    <b-form-input :disabled="isSaving" v-model="address.address_line_1" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors, 'address.address_line_2')" :state="validationState(errors, 'address.address_line_2')">
                    <b-form-input :disabled="isSaving" v-model="address.address_line_2" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                  <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors, 'address.city')" :state="validationState(errors, 'address.city')">
                    <b-form-input :disabled="isSaving" v-model="address.city" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                  <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors, 'address.county')" :state="validationState(errors, 'address.county')">
                    <b-form-input :disabled="isSaving" v-model="address.county" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                  <b-form-group label="Postcode" :invalid-feedback="validationInvalidFeedback(errors, 'address.postcode')" :state="validationState(errors, 'address.postcode')">
                    <b-form-input :disabled="isSaving" v-model="address.postcode" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="6">
                  <h2>Contacts</h2>
                </b-col>
                <b-col cols="6" md="2">
                  <b-button block :disabled="!contacts.length" :href="emailAllLink" variant="dark"><b-icon icon="envelope-open"></b-icon></b-button>
                </b-col>
                <b-col cols="6" md="4">
                  <b-button block @click.passive="editContact = {}" variant="primary" v-b-modal.edit-contact>Add Contact</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-2" cols="12">
                  <b-table responsive bordered striped head-variant="dark" :fields="['name', 'email', 'phone', 'actions']" :items="contacts">
                    <template v-slot:cell(name)="row">
                      {{ [row.item.surname.toUpperCase(), row.item.forename].join(', ') }}
                    </template>
                    <template v-slot:cell(phone)="row">
                      <p class="mb-0" v-if="row.item.landline"><strong>Landline:</strong> {{ row.item.landline }}</p>
                      <p class="mb-0" v-if="row.item.mobile"><strong>Mobile:</strong> {{ row.item.mobile }}</p>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <b-button variant="secondary" size="sm" @click="row.toggleDetails" class="mr-2 text-nowrap">{{ row.detailsShowing ? 'Hide' : 'Show'}} Details</b-button>
                      <b-button variant="dark" size="sm" :href="mailtoLinkFor(row.item)" class="mr-2 text-nowrap">Email Contact</b-button>
                    </template>

                    <template v-slot:row-details="row">
                      <b-card>
                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><strong>Landline Phone Number:</strong></b-col>
                          <b-col>{{ row.item.landline || 'N/A' }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><strong>Mobile Phone Number:</strong></b-col>
                          <b-col>{{ row.item.mobile || 'N/A' }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><strong>Notes:</strong></b-col>
                          <b-col>{{ row.item.notes || 'N/A' }}</b-col>
                        </b-row>

                        <b-button variant="secondary" size="sm" @click="row.toggleDetails" class="mr-2">Hide Details</b-button>
                        <b-button variant="primary" size="sm" @click="showEditContact(row.index)" class="mr-2">Edit Contact</b-button>
                        <b-button variant="danger" size="sm" @click="removeContact(row.index)" class="mr-2">Remove</b-button>
                      </b-card>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>
            <b-modal id="edit-contact" size="lg" cancel-title="Close" :ok-title="editContact.index < -1 ? 'Save' : 'Create'"
              :title="editContact.index < -1 ? 'Edit Contact' : 'Create Contact'" ok-variant="secondary" cancel-variant="dark"
              @ok="addContact" @cancel="cancelContact" :ok-disabled="contactSubmitDisabled">
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Forename">
                    <b-form-input v-model="editContact.forename" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Surname">
                    <b-form-input v-model="editContact.surname" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Position">
                    <b-form-input v-model="editContact.position" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Email Address">
                    <b-form-input v-model="editContact.email" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Landline Number">
                    <b-form-input v-model="editContact.landline" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Mobile Number">
                    <b-form-input v-model="editContact.mobile" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Contact Notes">
                    <b-form-textarea v-model="editContact.notes" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-modal>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" md="4" offset-md="8">
              <b-button block class="mb-3" :disabled="isSaving" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import get from 'lodash/get'
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'

const initialData = () => ({
  customer_id: 0,
  address: {
    address_line_1: null,
    address_line_2: null,
    city: null,
    county: null,
    postcode: null
  },
  contacts: [],

  customerSearchQuery: '',
  selectedCustomer: {},
  editContact: {}
})

export default {
  mixins: [validation],
  created () {
    if (this.$route.params.id) {
      this.fetchSite(this.$route.params.id)
    }

    if (this.$route.query.customer) {
      this.fetchCustomer(this.$route.query.customer).then(cust => {
        this.selectedCustomer = cust.data
        this.customer_id = cust.data.id
      })
    }
  },
  data () {
    return initialData()
  },
  beforeRouteLeave (_0, _1, next) {
    this.$store.dispatch('energy-sites/reset')
    Object.assign(this.$data, initialData())
    return next()
  },
  computed: {
    ...mapGetters('energy-customers', { customerSearchResults: 'data', isSearching: 'isLoading' }),
    ...mapGetters('energy-sites', ['errors', 'isLoading', 'isSaving', 'single']),
    emailAllLink () {
      const contacts = this.contacts.map(c => `${c.forename} ${c.surname} <${c.email}>`)
      return `mailto:${contacts.join(',')}?bcc=${process.env.VUE_APP_ADMIN_EMAIL}`
    },
    contactSubmitDisabled () {
      return !this.editContact.forename || !this.editContact.surname ||
             !this.editContact.email || !(
        (this.editContact.landline && this.editContact.landline.length === 11) ||
        (this.editContact.mobile && this.editContact.mobile.length === 11)
      )
    }
  },
  methods: {
    ...mapActions('energy-customers', ['fetchManyCustomers', 'fetchCustomer']),
    ...mapActions('energy-sites', ['fetchSite', 'saveSite']),
    addContact () {
      if (typeof this.editContact.index === 'undefined') {
        this.contacts.push(this.editContact)
      } else {
        this.contacts.$set(this.editContact.index, this.editContact)
      }

      this.editContact = {}
      this.$bvModal.hide('edit-contact')
    },
    cancelContact () {
      this.editContact = {}
    },
    mailtoLinkFor (contact) {
      return `mailto:${contact.forename} ${contact.surname} <${contact.email}>?bcc=${process.env.VUE_APP_ADMIN_EMAIL}`
    },
    removeContact (index) {
      this.contacts.splice(index, 1)
    },
    showEditContact (index) {
      this.editContact = { ...this.contacts[index], index }
      this.contacts.$set(index, this.editContact)
      this.$bvModal.show('edit-contact')
    },
    submit () {
      this.saveSite({
        id: this.$route.params.id,
        customer_id: this.customer_id,
        address: this.address,
        contacts: this.contacts
      }).then(response => {
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: `Site ${this.$route.params.id ? 'Updated' : 'Created'} Successfully`,
          text: 'This site has successfully been saved.'
        })

        if (!this.$route.params.id) {
          return this.$router.push({ name: 'energy.sites.update', params: { id: response.data.id } })
        }

        return this.fetchSite(this.$route.params.id)
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: `Error ${this.$route.params.id ? 'Updated' : 'Created'} Customer`,
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    }
  },
  watch: {
    single (value) {
      this.address = get(value, 'address')
      this.contacts = get(value, 'contacts')

      // Customer
      this.customer_id = get(value, 'customer.id')
      this.selectedCustomer = get(value, 'customer')
    }
  }
}
</script>

<style>

</style>
